<template>
  <div class="container">
    <div class="d-flex justify-content-center mt-5">
      <div class="col-md-5">
        <h1>We want to hear from you!</h1>
        <p class="text-muted">Guard.gg was founded on the principal of continuously improving our customer's experience. If you discover any bugs or just want to give us some feedback, leave us a note here. Make sure to leave your email if you want a response.</p>
        <div class="w-100">
          <form>
            <div class="form-group">
              <label for="">Email <span class="text-muted">(Optional)</span></label>
              <input class="form-control" type="text" name="" id="" v-model="email">
            </div>
            <div class="form-group">
              <label for="">Feedback Category</label>
              <select class="form-control" v-model="feedbackCategory">
                <option selected disabled>Feedback Type</option>
                <option value="Feedback">Feedback</option>
                <option value="Bug Report">Bug Report</option>
                <!-- <option value="Improvement">Improvement</option>
                <option value="Complaint">Complaint</option> -->
              </select>
            </div>
            
            <div class="form-group">
              <label for="">Message</label>
              <textarea v-model="content" class="form-control" name="" id="" cols="30" rows="10"></textarea>
              
            </div>

            <div class="mt-3 d-flex justify-content-between">
              <div>
                <p class="text-muted">{{remainingChars}} characters remaining</p>
              </div>

              <button
                class="btn btn-orange"
                type="button"
                @click="submit"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const api = require("../api");
import Swal from "sweetalert2";

export default {
  components: {},
  data() {
    return {
      content: "",
      feedbackCategory: "",
      email: ""
      
    };
  },
  computed: {
    remainingChars() {
      return 300 - this.content.length
    }
  },
  methods: {
    async submit() {
      Swal.showLoading()
      try {
        await api.post(`/feedback`, {
          content: this.content,
          category: this.feedbackCategory,
          email: this.email,
        });
        await Swal.fire("Thanks", "We've received your message and will be in touch.", "success");
      } catch (e) {
        await Swal.fire("Its not you, its us.", "We're unable to recevie your message right now, please try again later.", "error");
      }
    }
  },
  mounted() {
  },
  watch: {
  },
};
</script>

<style lang="scss">
.input-fix {
  border-radius: 0 !important;
}
</style>
